import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as _91section_934cVNZGti9iMeta } from "/vercel/path0/pages/account/[section].vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as databasev4JLRFROOMMeta } from "/vercel/path0/pages/account/sections/database.vue?macro=true";
import { default as historyjZrb2Eu4I0Meta } from "/vercel/path0/pages/account/sections/history.vue?macro=true";
import { default as linkedHOxJRxaleSMeta } from "/vercel/path0/pages/account/sections/linked.vue?macro=true";
import { default as me6yg1j9xS7cMeta } from "/vercel/path0/pages/account/sections/me.vue?macro=true";
import { default as preferencesKbp25BOzneMeta } from "/vercel/path0/pages/account/sections/preferences.vue?macro=true";
import { default as states3ur0GidQeMMeta } from "/vercel/path0/pages/account/sections/states.vue?macro=true";
import { default as updateAlw39xpa5eMeta } from "/vercel/path0/pages/account/update.vue?macro=true";
import { default as authI5JGIXywJyMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as changelogsaUcnbuokEMeta } from "/vercel/path0/pages/changelog.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as uiDqx8HufaEYMeta } from "/vercel/path0/pages/dev/ui.vue?macro=true";
import { default as wip7PTOE1MliuMeta } from "/vercel/path0/pages/dev/wip.vue?macro=true";
import { default as _91_46_46_46slug_93UUgP4euXUtMeta } from "/vercel/path0/pages/docs/[...slug].vue?macro=true";
import { default as _91slug_93UW1I6cRFThMeta } from "/vercel/path0/pages/game/[slug].vue?macro=true";
import { default as _91_46_46_46slug_9328RE0nnjmzMeta } from "/vercel/path0/pages/games/[...slug].vue?macro=true";
import { default as index3mhzZpolB9Meta } from "/vercel/path0/pages/genres/index.vue?macro=true";
import { default as _91platform_93SMSmS7s8PbMeta } from "/vercel/path0/pages/import/[platform].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as index83OYD4s8wXMeta } from "/vercel/path0/pages/journal/index.vue?macro=true";
import { default as _91_46_46_46slug_93UoUrkZwgn9Meta } from "/vercel/path0/pages/library/[...slug].vue?macro=true";
import { default as supabasePo1ao59FgEMeta } from "/vercel/path0/pages/supabase.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as theloginW0uUvaFR3kMeta } from "/vercel/path0/pages/thelogin.vue?macro=true";
import { default as welcomelxppa1y2l8Meta } from "/vercel/path0/pages/welcome.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "account-section",
    path: "/account/:section()",
    component: () => import("/vercel/path0/pages/account/[section].vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-sections-database",
    path: "/account/sections/database",
    component: () => import("/vercel/path0/pages/account/sections/database.vue").then(m => m.default || m)
  },
  {
    name: "account-sections-history",
    path: "/account/sections/history",
    component: () => import("/vercel/path0/pages/account/sections/history.vue").then(m => m.default || m)
  },
  {
    name: "account-sections-linked",
    path: "/account/sections/linked",
    component: () => import("/vercel/path0/pages/account/sections/linked.vue").then(m => m.default || m)
  },
  {
    name: "account-sections-me",
    path: "/account/sections/me",
    component: () => import("/vercel/path0/pages/account/sections/me.vue").then(m => m.default || m)
  },
  {
    name: "account-sections-preferences",
    path: "/account/sections/preferences",
    component: () => import("/vercel/path0/pages/account/sections/preferences.vue").then(m => m.default || m)
  },
  {
    name: "account-sections-states",
    path: "/account/sections/states",
    component: () => import("/vercel/path0/pages/account/sections/states.vue").then(m => m.default || m)
  },
  {
    name: "account-update",
    path: "/account/update",
    meta: updateAlw39xpa5eMeta || {},
    component: () => import("/vercel/path0/pages/account/update.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "changelog",
    path: "/changelog",
    component: () => import("/vercel/path0/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "dev-ui",
    path: "/dev/ui",
    component: () => import("/vercel/path0/pages/dev/ui.vue").then(m => m.default || m)
  },
  {
    name: "dev-wip",
    path: "/dev/wip",
    meta: wip7PTOE1MliuMeta || {},
    component: () => import("/vercel/path0/pages/dev/wip.vue").then(m => m.default || m)
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/vercel/path0/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "game-slug",
    path: "/game/:slug()",
    component: () => import("/vercel/path0/pages/game/[slug].vue").then(m => m.default || m)
  },
  {
    name: "games-slug",
    path: "/games/:slug(.*)*",
    component: () => import("/vercel/path0/pages/games/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "genres",
    path: "/genres",
    component: () => import("/vercel/path0/pages/genres/index.vue").then(m => m.default || m)
  },
  {
    name: "import-platform",
    path: "/import/:platform()",
    component: () => import("/vercel/path0/pages/import/[platform].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "journal",
    path: "/journal",
    component: () => import("/vercel/path0/pages/journal/index.vue").then(m => m.default || m)
  },
  {
    name: "library-slug",
    path: "/library/:slug(.*)*",
    component: () => import("/vercel/path0/pages/library/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "supabase",
    path: "/supabase",
    component: () => import("/vercel/path0/pages/supabase.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "thelogin",
    path: "/thelogin",
    component: () => import("/vercel/path0/pages/thelogin.vue").then(m => m.default || m)
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomelxppa1y2l8Meta || {},
    component: () => import("/vercel/path0/pages/welcome.vue").then(m => m.default || m)
  }
]